import { Button } from "@progress/kendo-react-buttons";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
	type CSSProperties,
	type ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { useToggle } from "react-use";
import { GenericDateRangePicker } from "./GenericDateRangePicker";
import { GenericStatusFilter } from "./GenericStatusFilter";
import {
	LegStatusType,
	NewJobStatus,
	type TableNameType,
	auditApi,
	invoiceStatusWithNameList,
	jobApi,
	jobStatusNamesAndColors,
	jobStatusWithNameList,
	legStatusWithNameList,
	useLS,
} from "./helpers";

type _DialogProps = {
	children: ReactNode;
	title?: string;
	onClose: () => void;
};

const _Dialog = ({ children, title, onClose }: _DialogProps) => {
	return (
		<Dialog title={title} onClose={onClose} autoFocus>
			{children}
		</Dialog>
	);
};

export const useDialog = (children: ReactNode, title?: string) => {
	const [showDialog, toggleDialog] = useToggle(false);
	return [
		toggleDialog,
		showDialog && (
			<_Dialog key={title} title={title} onClose={toggleDialog}>
				{children}
			</_Dialog>
		),
	] as const;
};

type _DialogConfirmProps = {
	children: ReactNode;
	title: string;
	onConfirm: () => void;
	toggleDialog: () => void;
};

const _DialogConfirm = ({
	children,
	title,
	onConfirm,
	toggleDialog,
}: _DialogConfirmProps) => (
	<Dialog title={title} onClose={toggleDialog}>
		{children}
		<DialogActionsBar>
			<Button onClick={toggleDialog}>No</Button>
			<Button onClick={onConfirm} autoFocus>
				Yes
			</Button>
		</DialogActionsBar>
	</Dialog>
);

export const useDialogConfirm = (
	children: ReactNode,
	title: string,
	onConfirm: () => void,
) => {
	const [showDialog, toggleDialog] = useToggle(false);
	const handleConfirm = useCallback(() => {
		onConfirm();
		toggleDialog();
	}, [onConfirm, toggleDialog]);
	return [
		toggleDialog,
		showDialog && (
			<_DialogConfirm
				key={title}
				title={title}
				onConfirm={handleConfirm}
				toggleDialog={toggleDialog}
			>
				{children}
			</_DialogConfirm>
		),
	] as const;
};

export const toCell = (node: string) => <td title={node}>{node}</td>;

const defaultValue = {
	start: dayjs().startOf("M").toDate(),
	end: dayjs().endOf("M").toDate(),
};
export const useGenericDateRangePicker = (name: string) => {
	const [rangeValues, setRangeValues] = useLS<SelectionRange>(
		`${name}-RangeValues`,
		defaultValue,
	);
	const element = (
		<div>
			<GenericDateRangePicker value={rangeValues} onChange={setRangeValues} />
		</div>
	);
	return [element, rangeValues] as const;
};

const jobStatusGroups = [
	{
		name: "Operation",
		values: [
			NewJobStatus.NEW,
			NewJobStatus.PLANNED,
			NewJobStatus.LOADED,
			NewJobStatus.COMPLETED,
			NewJobStatus.PAUSED,
		],
	},
	{
		name: "Accounting",
		values: [
			NewJobStatus.READY_FOR_INVOICE,
			NewJobStatus.CHECKED,
			NewJobStatus.INVOICE_GENERATED,
			NewJobStatus.INVOICE_SENT,
			NewJobStatus.INVOICE_FAILED,
			NewJobStatus.READY_FOR_REINVOICE,
			NewJobStatus.CHECKED_AGAIN,
			NewJobStatus.REINVOICE_GENERATED,
			NewJobStatus.REINVOICE_SENT,
			NewJobStatus.SCHEDULED_FOR_INVOICE,
		],
	},
	{ name: "Archived", values: [NewJobStatus.CANCELLED, NewJobStatus.ARCHIVED] },
	{
		name: "All",
		values: [
			NewJobStatus.UNKNNOWN,
			NewJobStatus.NEW,
			NewJobStatus.PLANNED,
			NewJobStatus.LOADED,
			NewJobStatus.COMPLETED,
			NewJobStatus.PAUSED,
			NewJobStatus.CANCELLED,
			NewJobStatus.ARCHIVED,
			NewJobStatus.READY_FOR_INVOICE,
			NewJobStatus.CHECKED,
			NewJobStatus.INVOICE_GENERATED,
			NewJobStatus.INVOICE_SENT,
			NewJobStatus.INVOICE_FAILED,
			NewJobStatus.READY_FOR_REINVOICE,
			NewJobStatus.CHECKED_AGAIN,
			NewJobStatus.REINVOICE_GENERATED,
			NewJobStatus.REINVOICE_SENT,
			NewJobStatus.SCHEDULED_FOR_INVOICE,
		],
	},
];
export const useGenericJobStatusFilter = (name: string) => {
	const [values, setValues] = useLS<number[]>(`${name}-StatusValues`, []);
	const element = (
		<div>
			<GenericStatusFilter
				values={values}
				onChange={setValues}
				possibleValues={jobStatusWithNameList}
				groups={jobStatusGroups}
			/>
		</div>
	);
	return [element, values] as const;
};

const invoicingCheckStatusGroupsAll = [
	NewJobStatus.READY_FOR_INVOICE,
	NewJobStatus.READY_FOR_REINVOICE,
	NewJobStatus.CHECKED,
	NewJobStatus.CHECKED_AGAIN,
];
const invoicingCheckStatusGroupsAllWithNames = invoiceStatusWithNameList.filter(
	(x) => invoicingCheckStatusGroupsAll.includes(x.id),
);
const invoicingCheckStatusGroups = [
	{
		name: "To Check",
		values: [NewJobStatus.READY_FOR_INVOICE, NewJobStatus.READY_FOR_REINVOICE],
	},
	{
		name: "Checked",
		values: [NewJobStatus.CHECKED, NewJobStatus.CHECKED_AGAIN],
	},
	{
		name: "All",
		values: invoicingCheckStatusGroupsAll,
	},
];
export const useGenericInvoicingCheckStatusFilter = (name: string) => {
	const [values, setValues] = useLS<number[]>(
		`${name}-StatusValues`,
		invoicingCheckStatusGroupsAll,
	);
	const element = (
		<div>
			<GenericStatusFilter
				values={values}
				onChange={setValues}
				possibleValues={invoicingCheckStatusGroupsAllWithNames}
				groups={invoicingCheckStatusGroups}
			/>
		</div>
	);
	return [element, values] as const;
};

const invoicingStatusGroups = [
	{
		name: "To Invoice",
		values: [
			NewJobStatus.READY_FOR_INVOICE,
			NewJobStatus.CHECKED,
			NewJobStatus.INVOICE_GENERATED,
			NewJobStatus.INVOICE_FAILED,
			NewJobStatus.READY_FOR_REINVOICE,
			NewJobStatus.CHECKED_AGAIN,
			NewJobStatus.REINVOICE_GENERATED,
		],
	},
	{
		name: "Invoiced",
		values: [NewJobStatus.INVOICE_SENT, NewJobStatus.REINVOICE_SENT],
	},
	{ name: "All", values: [] },
];
export const useGenericInvoicingStatusFilter = (name: string) => {
	const [values, setValues] = useLS<number[]>(`${name}-StatusValues`, []);
	const element = (
		<div>
			<GenericStatusFilter
				values={values}
				onChange={setValues}
				possibleValues={invoiceStatusWithNameList}
				groups={invoicingStatusGroups}
			/>
		</div>
	);
	return [element, values] as const;
};

// leg do, Operating, Finished, Archived, All
const legStatusGroups = [
	{
		name: "Operating",
		values: [
			LegStatusType.New,
			LegStatusType.Planned,
			LegStatusType.Accepted,
			LegStatusType.InStartPosition,
			LegStatusType.Underway,
			LegStatusType.InEndPosition,
			LegStatusType.NotStarted,
			LegStatusType.DocumentsReady,
			LegStatusType.Clearing,
			LegStatusType.CustomsCleared,
			LegStatusType.PartOfLoad,
			LegStatusType.ReadyForWork,
			LegStatusType.BookingInProgress,
			LegStatusType.Booked,
			LegStatusType.Paused,
		],
	},
	{
		name: "Finished",
		values: [LegStatusType.SignedOff, LegStatusType.WorkFinished],
	},
	{
		name: "Archived",
		values: [LegStatusType.Archived, LegStatusType.Cancelled],
	},
	{ name: "All", values: [] },
];
export const useGenericLegStatusFilter = (name: string) => {
	const [values, setValues] = useLS<number[]>(`${name}-StatusValues`, []);
	const element = (
		<div>
			<GenericStatusFilter
				values={values}
				onChange={setValues}
				possibleValues={legStatusWithNameList}
				groups={legStatusGroups}
			/>
		</div>
	);
	return [element, values] as const;
};

export const useGoodsSelector = () => {
	const [legId, setLegId] = useState<number>();
	const [callback, setCallback] =
		useState<(goodsId: number) => Promise<unknown>>();
	const goods = useQuery({
		queryKey: ["jobApi.leg.getLeg2", legId],
		queryFn: async () => {
			if (!legId) return [];
			const leg = await jobApi.leg.getLeg2(legId);
			const goods =
				leg.data.goods?.map((g) => ({
					id: g.id,
					name: g.name,
					quantity: g.quantity,
				})) ?? [];
			return goods;
		},
		initialData: [],
	});
	const [selectedGoodsId, setSelectedGoodsId] = useState<number>();
	let element: ReactNode = (
		<>
			<RadioGroup
				data={goods.data.map((x) => ({ label: x.name, value: x.id }))}
				onChange={(e) => setSelectedGoodsId(e.value)}
			/>
			<DialogActionsBar>
				<Button onClick={() => toggleModal(false)}>No</Button>
				<Button
					onClick={async () => {
						if (!selectedGoodsId) return;
						if (!callback) return;
						await callback(selectedGoodsId).finally(() => toggleModal(false));
					}}
					disabled={!selectedGoodsId}
					autoFocus
				>
					Yes
				</Button>
			</DialogActionsBar>
		</>
	);
	if (goods.error) element = `Error: ${goods.error.message}`;
	if (goods.isLoading) element = "Loading...";
	const [toggleModal, modal] = useDialog(element, "Select Goods");
	return [
		(legId: number, callback: (goodsId: number) => Promise<unknown>) => {
			setLegId(legId);
			setSelectedGoodsId(undefined);
			setCallback(() => callback);
			toggleModal(true);
		},
		modal,
	] as const;
};

type JobStatusCellProps = {
	status: number;
	name: string;
	title?: string;
	isNew?: boolean;
};
export const JobStatusCell = ({
	status,
	name,
	title,
	isNew = false,
}: JobStatusCellProps) => {
	return (
		<td
			title={title ?? name}
			style={{ textAlign: "center", backgroundColor: isNew ? "yellow" : "" }}
		>
			<JobStatusTag status={status} big />
		</td>
	);
};

type JobStatusTag = {
	status: NewJobStatus;
	big?: boolean;
};
export const JobStatusTag = ({ status, big }: JobStatusTag) => {
	const statusWithColors = jobStatusNamesAndColors[status];
	return (
		<DTag
			backgroundColor={statusWithColors.backgroundColor}
			color={statusWithColors.color}
			big={big}
		>
			{statusWithColors.name}
		</DTag>
	);
};

type DTagProps = {
	children: ReactNode;
	backgroundColor?: CSSProperties["backgroundColor"];
	color?: CSSProperties["color"];
	big?: boolean;
};
export const DTag = ({
	children,
	backgroundColor = "lightgray",
	color = "black",
	big,
}: DTagProps) => (
	<span
		style={{
			backgroundColor,
			color,
			borderRadius: "15px",
			padding: big ? "5px 20px" : "2px 10px",
			fontSize: big ? undefined : "12px",
			paddingTop: big ? "5px" : "3px",
		}}
	>
		{children}
	</span>
);

const mergeLists = (keys: string[], values: string[]) =>
	keys.map((key, index) => `${key}: ${values[index]}`).join("\n");

export const useAuditForm = (_type: TableNameType) => {
	const [type, setType] = useState(_type);
	const [id, setId] = useState<string | number>();

	const { data, isFetching } = useQuery({
		queryKey: ["auditApi.audit.auditV1SearchHistoryList", type, id],
		queryFn: async () => {
			if (!id) {
				return [];
			}

			const history = await auditApi.audit.auditV1SearchHistoryList({
				Type: type,
				KeyValues: `${id}`,
			});

			if (history.data.length === 0) {
				const audit = await auditApi.audit.auditV1SearchList({
					Type: type,
					KeyValues: `${id}`,
				});

				return audit.data.map((x) => ({
					...x,
					date: x.auditDate,
					user: x.auditUser,
					keys: JSON.parse(x.affectedColumns ?? "[]"),
					previousValue: JSON.parse(x.oldValues ?? "{}"),
					currentValues: JSON.parse(x.newValues ?? "{}"),
				}));
			}

			return history.data
				.sort((one, two) => (new Date(one.date) > new Date(two.date) ? -1 : 1))
				.map((x) => ({
					...x,
					keys: x.records.map((record) => record.property),
					previousValue: x.records.map(
						(record) => record.previousValue?.toString() || "",
					),
					currentValues: x.records.map(
						(record) => record.currentValue?.toString() || "",
					),
				}));
		},
		initialData: [],
	});

	const dialogBody = useMemo(
		() => (
			<table>
				<thead>
					<tr>
						<th>Date</th>
						<th>Username</th>
						<th>Diff</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((x) => (
						<tr key={x.id}>
							{toCell(dayjs(x.date).format("YYYY-MM-DD HH:mm:ss"))}
							{toCell(x.user ?? "?")}
							<td style={{ minWidth: "600px" }}>
								<ReactDiffViewer
									oldValue={mergeLists(x.keys, x.previousValue)}
									newValue={mergeLists(x.keys, x.currentValues)}
									hideLineNumbers
									hideMarkers
									compareMethod={DiffMethod.WORDS}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		),
		[data],
	);

	const [toggleDialog, auditDialog] = useDialog(
		dialogBody,
		isFetching ? "Audit Loading..." : "Audit",
	);

	const showAuditFor = useCallback(
		(id: string | number, _newType?: TableNameType) => {
			setId(id);
			if (_newType) setType(_newType);
			toggleDialog(true);
		},
		[toggleDialog],
	);

	return { showAuditFor, auditDialog };
};
